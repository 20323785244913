import { template as template_bad15e09c11d42c4ba4c4c558f61c049 } from "@ember/template-compiler";
const FKLabel = template_bad15e09c11d42c4ba4c4c558f61c049(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
